import React from 'react';
import '../styles/Navbar.css';

const NavbarHidden = () => {
  return (
    <div id='NavbarHidden'>

    </div>
  )
}

export default NavbarHidden